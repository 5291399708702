import { useGetInvalidEligibleEmployees } from '@src/api/supportTool/eligibleEmployees'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { InvalidEligibilityItemProps } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/ListItems'
import { adaptInvalidEligibilityObjects } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/hooks/adaptInvalidEligibilityObjects'

interface InvalidEligibilityObjects {
  itemProps: InvalidEligibilityItemProps[]
  isLoading: boolean
  isError: boolean
  handleFetchMore: VoidFunction
}

export const useInvalidEligibilityObjects = (
  cycle: ReviewCyclesInterface,
): InvalidEligibilityObjects => {
  const { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetInvalidEligibleEmployees(cycle.id)
  const itemProps = adaptInvalidEligibilityObjects(data?.pages ?? [])

  const handleFetchMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  return {
    itemProps,
    isLoading,
    isError,
    handleFetchMore,
  }
}

import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  EligibleEmployeesInterface,
  EligibleEmployeesStats,
} from '@src/interfaces/supportTool/eligibleEmployees'
import { FilterByInterface } from '@src/interfaces/data'
import {
  useFetch,
  useInfiniteFetch,
  usePost,
  UseQueryOptions,
} from '@src/utils/reactQuery'
import { ScorecrdsGenerationsCategory } from '@src/interfaces/scorecard'

export const uploadEmployees = async (file: File) => {
  const formData = new FormData()
  formData.append('eligible_employees_file', file, file.name)

  return api.post(
    `${API.SUPPORT_TOOL}/eligibleEmployees/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': `attachment`,
      },
    },
    'v2',
  )
}

export const eligibleEmployeesRequests: RequestInterface<EligibleEmployeesInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.SUPPORT_TOOL}/eligibleEmployees`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getItem: async id =>
    api.get(`${API.SUPPORT_TOOL}/eligibleEmployees/${id}`, undefined, 'v2'),
  patchItem: async (data, id) =>
    api.patch(`${API.SUPPORT_TOOL}/eligibleEmployees/${id}`, data, undefined, 'v2'),
  deleteItem: async id =>
    api.delete(`${API.SUPPORT_TOOL}/eligibleEmployees/${id}`, undefined, 'v2'),
  postItem: async data =>
    api.post(`${API.SUPPORT_TOOL}/eligibleEmployees`, data, undefined, 'v2'),
}

export const formEligibleEmployeesRequest: RequestInterfaceNew<EligibleEmployeesInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.SUPPORT_TOOL}/eligibleEmployees/${id}`, undefined, 'v2'),
    update: async (data, { id }) =>
      api.patch(`${API.SUPPORT_TOOL}/eligibleEmployees/${id}`, data, undefined, 'v2'),
    submit: async data =>
      api.post(`${API.SUPPORT_TOOL}/eligibleEmployees`, data, undefined, 'v2'),
    delete: async ({ id }) =>
      api.delete(`${API.SUPPORT_TOOL}/eligibleEmployees/${id}`, undefined, 'v2'),
  }

export const regenerateEligibleEmployeesScorecards = (id: number) =>
  api.post(
    `${API.SUPPORT_TOOL}/eligibleEmployees/${id}/regenerateScorecards`,
    {},
    undefined,
    'v2',
  )

export const triggerCycleSync = (cycle_id: string) =>
  api.post(
    `${API.SUPPORT_TOOL}/eligibleEmployees/updateFromGroups`,
    {
      cycle_id,
    },
    undefined,
    'v2',
  )

export const triggerGenerateScorecards = (
  cycle: number | string,
  categories: string[],
  filters?: FilterByInterface[],
) =>
  api.post(
    `${API.SUPPORT_TOOL}${API.SCORECARDS_GENERATIONS}`,
    {
      cycle,
      categories,
    },
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    'v2',
  )

export const syncEligibleEmployeeData = (
  cycle_id: number | string,
  filters?: FilterByInterface[],
) =>
  api.post(
    `${API.SUPPORT_TOOL}/eligibleEmployees/syncReviews`,
    { cycle_id, eligible_ids: [] },
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    'v2',
  )

export const validateEligibleEmployeeData = (
  cycle_id: number | string,
  filters?: FilterByInterface[],
) =>
  api.post(
    `${API.SUPPORT_TOOL}/eligibleEmployees/validate`,
    { cycle_id, eligible_ids: [] },
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    'v2',
  )

export const useGetScorecardsGenerationsCategories = () =>
  useFetch<ScorecrdsGenerationsCategory[]>(
    `${API.SUPPORT_TOOL}${API.SCORECARDS_GENERATIONS}/categories`,
    'v2',
  )

export const useGetScorecardsGenerationsStatus = () =>
  useFetch(`${API.SUPPORT_TOOL}${API.SCORECARDS_GENERATIONS}/status`, 'v2')

export const useTriggerCycleSync = () =>
  usePost<{ cycle_id: string }>(
    `${API.SUPPORT_TOOL}/eligibleEmployees/updateFromGroups`,
    'v2',
    undefined,
    undefined,
    true,
  )

export const useGetEligibleEmployeesStats = (
  cycleId: string | number,
  queryOptions?: UseQueryOptions<EligibleEmployeesStats>,
) =>
  useFetch<EligibleEmployeesStats>({
    url: `${API.SUPPORT_TOOL}/eligibleEmployees/stats`,
    params: { params: { cycle__id: cycleId } },
    version: 'v2',
    queryOptions,
  })

export const useTriggerValidateScorecards = () =>
  usePost<{ cycle_id: string | number }>(
    `${API.SUPPORT_TOOL}/eligibleEmployees/validateAll`,
    'v2',
  )

export const useGetInvalidEligibleEmployees = (cycleId: number | string) =>
  useInfiniteFetch<GetRequestInterface<EligibleEmployeesInterface>>({
    url: `${API.SUPPORT_TOOL}/eligibleEmployees`,
    version: 'v2',
    params: { params: { cycle__id: cycleId, is_valid: false } },
    queryOptions: {
      getNextPageParam: ({ pages }) => pages.next,
    },
  })

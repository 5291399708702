import React from 'react'
import { useParams } from 'react-router'
import { VStack } from '@revolut/ui-kit'
import {
  useGetEligibleGroupsInfo,
  useGetReviewCycle,
  useGetReviewCycleGeneralInfo,
} from '@src/api/reviewCycles'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import PageLoading from '@components/PageLoading/PageLoading'
import { useIsTestPerformanceCycleEnabled } from '@src/pages/Forms/ReviewCycle/ReviewCycle/useIsTestPerformanceCycleEnabled'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import ReviewCycleGeneral from '@src/pages/Forms/ReviewCycle/General'
import { CycleSummary } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/CycleSummary'
import { CycleStages } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/CycleStages'
import { EligibleEmployees } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EligibleEmployees'
import { CalibrationLogic } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/CalibrationLogic'
import { CycleSettings } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSettings/CycleSettings'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

export const ReviewCycle = () => {
  const params = useParams<{ id: string }>()

  const { data: cycle, isLoading: isCycleLoading } = useGetReviewCycle(params.id)
  const { data: cycleInfo, isLoading: isCycleInfoLoading } = useGetReviewCycleGeneralInfo(
    params.id,
  )
  const { data: groupsInfo, isLoading: isGroupsInfoLoading } = useGetEligibleGroupsInfo(
    params.id,
  )
  const { data: settings } = useGetPerformanceSettings()

  const eligibleGroups = groupsInfo?.results ?? []

  if (isCycleLoading || cycle === undefined) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title={<CycleSummary cycle={cycle} />}
        backUrl={ROUTES.PERFORMANCE.REVIEWS_CYCLES}
      />
      <TwoColumnsLayout
        leftFlex={2}
        rightFlex={1}
        left={
          <VStack space="s-16">
            <CycleStages
              cycle={cycle}
              eligibleGroups={eligibleGroups}
              settings={settings}
            />
            <CycleSettings cycle={cycle} />
            <CalibrationLogic cycle={cycle} />
          </VStack>
        }
        right={
          <EligibleEmployees
            count={cycleInfo?.eligible_employees_count ?? 0}
            groups={eligibleGroups}
            isLoading={isCycleInfoLoading || isGroupsInfoLoading}
          />
        }
      />
    </PageWrapper>
  )
}

export const ReviewCyclePage = () => {
  const isTestCycleEnabled = useIsTestPerformanceCycleEnabled()

  if (!isTestCycleEnabled) {
    return <ReviewCycleGeneral />
  }

  return <ReviewCycle />
}

import * as React from 'react'
import { ActionButton, Avatar, Box, Item, VStack, Widget } from '@revolut/ui-kit'
import { useParams } from 'react-router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { CycleTimeline } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/CycleTimeline'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  settings?: PerformanceSettingsInterface
}

export const CycleStages = ({ cycle, eligibleGroups, settings }: Props) => {
  const params = useParams<{ id: string }>()
  const editStagePath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.TIMELINE, params)

  return (
    <Widget>
      <VStack space="s-8">
        <Item>
          <Item.Avatar>
            <Avatar useIcon="TurboTransfer" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Cycle stages</Item.Title>
            <Item.Description>Manage cycles stages and notifications</Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton to={editStagePath} use={InternalLink}>
              Edit
            </ActionButton>
          </Item.Side>
        </Item>
        <Box mx="s-8" mb="s-8">
          <CycleTimeline
            cycle={cycle}
            eligibleGroups={eligibleGroups}
            settings={settings}
          />
        </Box>
      </VStack>
    </Widget>
  )
}
